const arrayBufferToBase64 = (buffer) => {
  return btoa(String.fromCharCode(...new Uint8Array(buffer)))
}

const importPublicKey = async () => {
  const pem = process.env.GATSBY_FILE_PW_PUBLIC_KEY.replace(/\\n/g, "\n")
  const b64 = pem
    .replace(/-----(BEGIN|END) PUBLIC KEY-----/g, "")
    .replace(/\s+/g, "")
  const keyData = Uint8Array.from(atob(b64), (c) => c.charCodeAt(0))

  return await crypto.subtle.importKey(
    "spki",
    keyData,
    { name: "RSA-OAEP", hash: "SHA-256" },
    true,
    ["encrypt"]
  )
}

export const encryptData = async (data) => {
  const encoder = new TextEncoder()
  const encodedData = encoder.encode(data)

  const publicKey = await importPublicKey()

  const encryptedData = await crypto.subtle.encrypt(
    {
      name: "RSA-OAEP",
    },
    publicKey,
    encodedData
  )

  const encryptedByteArr = new Uint8Array(encryptedData)

  return arrayBufferToBase64(encryptedByteArr)
}
